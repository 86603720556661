













import { Vue, Component, Prop, Ref } from 'vue-property-decorator';

@Component({})
export default class ColorItem extends Vue {

  @Prop()
  private color: string;

  @Prop()
  private deletable: boolean;

  @Prop({default: 'md'})
  private size: string;

  @Ref() container: HTMLElement;

  private showDelete = false;

  mounted(){
    // (this.$refs.container as any).style.height = `${(this.$refs.container as Element)?.clientWidth || 50}px`;
    // (this.$refs.container as any).style.background = this.color;
  }
  get style() {

    return { background: this.color };
  }

  get containerClass() {
    return `color-${this.size}`
  }

  deleteColor(){
    this.$emit('destroy')
  }
}
